import React from "react"
import PropTypes from "prop-types"
import SimpleReactValidator from "simple-react-validator"
import { navigateTo } from "gatsby"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: "",
      last: "",
      email: "",
      phone: "",
      company: "",
      website: "",
      submitting: false
    }
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = e => {
    if (this.validator.allValid()) {
      const form = e.target;
      this.setState({
        submitting: true
      })
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { first, last, email, phone, company } = this.state;
    return (
      <section className="mb-16 lg:mb-32 overflow-hidden">
        <div className="container text-center">

          <form
            name="Event Form"
            onSubmit={this.handleSubmit}
            className="block w-full mx-auto max-w-lg text-left feedbackform"
            method="post"
            action="/thank-you"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" className="hidden" name="form-name" value="callback-form" />
            <p hidden className="hidden"><label>Don't fill this out:{" "}<input name="bot-field" /></label></p>

            <div className="flex flex-wrap md:-mx-3">
              <div className="w-full md:w-1/2 md:px-3">
                <label htmlFor="first" className="block mb-2">
                  <span className="text-white font-display pt-2 pb-1 block">First Name: *</span>
                  <input type="text" name="first" id="first" className="text-black block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none" placeholder="First Name" value={first} onChange={this.handleChange} />

                  <span className="block mt-2 text-primary text-sm">
                    {this.validator.message('first', first, 'required')}
                  </span>
                </label>
              </div>
              
              <div className="w-full md:w-1/2 md:px-3">
                <label htmlFor="lastname" className="block mb-2">
                  <span className="text-white font-display pt-2 pb-1 block">Last Name: *</span>
                  <input type="text" name="last" id="last" className="text-black block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none" placeholder="Last Name" value={last} onChange={this.handleChange} />

                  <span className="block mt-2 text-primary text-sm">
                    {this.validator.message('last', last, 'required')}
                  </span>
                </label>
              </div>
            </div>

            <div className="flex flex-wrap md:-mx-3">
              <div className="w-full md:w-1/2 md:px-3">
                <label htmlFor="email" className="block mb-2">
                  <span className="text-white font-display pt-2 pb-1 block">Email: *</span>
                  <input type="email" name="email" id="email" className="text-black block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none" placeholder="Email" value={email} onChange={this.handleChange} />
                    
                  <span className="block mt-2 text-primary text-sm">
                    {this.validator.message('email', email, 'required|email')}
                  </span>
                </label>
              </div>
              <div className="w-full md:w-1/2 md:px-3">
                <label htmlFor="phone" className="block mb-2">
                  <span className="text-white font-display pt-2 pb-1 block">Phone number: *</span>
                  <input type="text" name="phone" id="phone" className="text-black block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none" placeholder="0800 123 4567" value={phone} onChange={this.handleChange} />

                  <span className="block mt-2 text-primary text-sm">
                    {this.validator.message('phone', phone, 'required|phone')}
                  </span>
                </label>
              </div>
            </div>

            <label htmlFor="company" className="block mb-2">
              <span className="text-white font-display pt-2 pb-1 block">Company: *</span>

              <input type="text" name="company" id="company" className="text-black block w-full p-4 border border-grey-medium outline-none focus:border-primary appearance-none" placeholder="Company" value={company} onChange={this.handleChange} />
                
              <span className="block mt-2 text-primary text-sm">
                {this.validator.message('company', company, 'required|string')}
              </span>
            </label>

            <div className="flex justify-center mt-8">
              <button type="submit" disabled={this.state.submitting} className="btn btn--primary">{ this.state.submitting ? 'Sending...' : 'Register' }</button>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

RegisterForm.propTypes = {
  heading: PropTypes.string.isRequired,
}

RegisterForm.defaultProps = {
  heading: `Want to know more?`,
}

export default RegisterForm
